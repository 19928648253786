// import React, {useState} from 'react'
import React, {useCallback, useState} from 'react'
// import React, {useState, useCallback, useRef, useEffect, useMemo} from 'react'
// import './cariAnimations.css'
import {BiMessageDetail} from 'react-icons/bi'
import {FaInstagram, FaTelegram} from 'react-icons/fa'
import {MdOutlineMarkEmailRead} from 'react-icons/md'
import {ContactBG, SecretaryStandby, SendNote, TakingNotes} from '../../img'
import './contact.css'
import {FormContainer} from './contactForm'
import './contactPref.css'
// import DividerWave from '../../../components/divider_wave/divider_wave'
// MdAlternateEmail
// import {useWhyDidYouUpdate} from '../../../utils/useWhyDidYouUpdate'

const contact_text = "Let's chat and bring your vision to life! Just fill out this shiny form to get the ball rolling."

// eslint-disable-next-line no-unused-vars
const FieldOptions = ({setPref}) => {
	return (
		<fieldset name='pref_method' className='flex-to-center p-4' defaultValue='' onChange={setPref}>
			{/* <legend>Choose a Contact method:</legend> */}
			<input type='radio' id='email' name='method' />
			<label htmlFor='email'>
				<MdOutlineMarkEmailRead className='icon' />
				<h6>Email</h6>
			</label>
			<input type='radio' id='instagram' name='method' />
			<label htmlFor='instagram'>
				<FaInstagram className='icon' />
				<h6>Instagram</h6>
			</label>
			<br />
			<input type='radio' id='telegram' name='method' />
			<label htmlFor='telegram'>
				<FaTelegram className='icon' />
				<h6>Telegram</h6>
			</label>
			<input type='radio' id='direct' name='method' />
			<label htmlFor='direct'>
				<BiMessageDetail className='icon' />
				{/* <MdAlternateEmail className='icon' /> */}
				<h6>Stay on site</h6>
			</label>
		</fieldset>
	)
}

// const getNumBetween = (min, max) => Math.floor(Math.random() * (max - min) + min)
const Contact = (props) => {
	// const {id, forwardRef} = props
	// const {id, forwardRef, updateFormValues, formValues} = props
	// useWhyDidYouUpdate('Contact', props)

	// const [showEmail, setShowEmail] = useState(true)
	const [selectedSubject, setSelectedSubject] = useState(false)
	//is user typing
	// const [typingCount, setTypingCount] = useState(0)
	// const [writingTimer, setWritingTimer] = useState(null)

	// eslint-disable-next-line no-unused-vars
	const [messageSent, setMessageSent] = useState(false)

	const [isTyping, setIsTyping] = useState(false)

	// const setPref = (event) => {
	// 	// console.log('setPref event', event.target)
	// 	// const {id, name} = event.target
	// 	// console.log('setPref event name', id, name)

	// 	setSelectedSubject(true)
	// 	// handleFormChange(event)
	// }

	const handleFocus = () => {
		setIsTyping(true)
	}
	const handleBlur = () => {
		setTimeout(() => setIsTyping(false), 500)
	}
	const handleFormChange = (event) => {
		// console.log('Contact handleFormChange name', event.target.name)
		// updateFormValues({[event.target.name]: event.target.name})
		// console.log('handleFormChange formValues', formValues)
		handleFocus()
		setTimeout(() => handleBlur, 1500)

		// making sure the typing count doesnt get too high
		// setTypingCount((typingCount) => (typingCount <= 2 ? typingCount + 1 : typingCount))
		// if (writingTimer) {
		// 	clearTimeout(writingTimer)
		// 	setWritingTimer(null)
		// }

		// const newWritingTimer = setTimeout(() => {
		// 	setTypingCount(0)
		// }, getNumBetween(1, 2) * (typingCount + 1) * 1000)
		// setWritingTimer(newWritingTimer)

		if (event.target.name === 'subject') setSelectedSubject(true)
	}
	const SecretaryImage = useCallback(
		({className}) => {
			if (!selectedSubject)
				return (
					<div className={className}>
						<SecretaryStandby />
					</div>
				)
			else if (messageSent)
				return (
					<div className={className}>
						<SendNote />
					</div>
				)
			else {
				return (
					<div id='writingSec' className={className}>
						<TakingNotes />
					</div>
				)
			}
		},
		[selectedSubject, messageSent]
	)
	const id = 'contact'
	return (
		<section
			className='z-contact relative bg-offwhite flex-to-center flex-col overflow-hidden'
			id={id}
			data-section={id}
			// ref={forwardRef}
		>
			{/* <img src={ContactBG} alt='' className=''/> */}
			{/* <div className={`w-full h-full flex-to-center flex-col`}> */}
			<div
				className={`absolute z-0 w-full h-full `}
				style={{
					backgroundImage: `url('${ContactBG}')`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}></div>
			{/* <DividerWave direction='up' className='-translate-y-full fill-offwhite' /> */}
			<h1 className='text-5xl text-center lg:text-left p-6 text-black font-lucky bg-white/80 backdrop-blur-sm rounded-lg z-10 my-12'>Contact</h1>
			<div className='relative max-w-6xl flex-to-center flex-col md:flex-row bg-white bg-white/80 backdrop-blur-sm rounded-lg z-10 px-6 py-10 mb-12 ml-4 mr-8'>
				<div className='form_section transition-all duration-[5s]'>
					{/* <label>Choose a Contact method:</label> */}
					{!messageSent && <p className='text-md text-black mb-8'>{contact_text}</p>}
					<FormContainer
						// formValues={formValues}
						// updateFormValues={updateFormValues}
						contactMethod={selectedSubject}
						onChange={handleFormChange}
						setMessageSent={setMessageSent}
						messageSent={messageSent}
						handleFocus={handleFocus}
						handleBlur={handleBlur}
					/>
				</div>
				<div className='hidden md:flex self-end relative pt-6 md:pt-0 max-w-[50vw]'>
					<SecretaryImage className={`doodle fill-black stroke-black z-10 h-[40rem] w-auto ${isTyping ? ' drawing' : ''}`} />
					{/* min-w-["min(200px, 30vw)"] */}
				</div>
			</div>
			{/* </div> */}
			{/* <DividerWave direction='down' className='translate-y-[99%] fill-offwhite h-[0]' /> */}
		</section>
	)
}

export default Contact
