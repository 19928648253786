import React from 'react'
// eslint-disable-next-line
import Border from '../components/Border'
import {GreenArrowDown} from '../img'
import howDesktop from '../img/doodles/HowItWorks_desktop.png'
import howStep1 from '../img/doodles/HowItWorks_mobile1.png'
import howStep2 from '../img/doodles/HowItWorks_mobile2.png'
import howStep3 from '../img/doodles/HowItWorks_mobile3.png'

const texts = {
	text1: "Press 'get mine now' to get the ball rolling.",
	text2: 'We discuss the details (sending the best photos etc.) in order to make your caricature the best it can be!',
	text3: 'Within 3-7 days the art piece will be done and sent via preferred method (if printed, shipping time may very pending on your location.)',
	note: 'Note: high resolution digital file is sent together with the print.',
}

const Paragraph = ({text}) => {
	return <p className='w-3/4 sm:w-1/2 self-center mt-12'>{text}</p>
}
const IndividualStep = ({image, text}) => {
	return (
		<div className='relative flex flex-col justify-center text-left h-auto md:h-full'>
			<img src={image} className='object-contain max-h-[50vh] h-fullh-fit w-full ' alt='How to get your own caricature, visual explanation.' />
			<Paragraph text={text} />
		</div>
	)
}
const HowItWorksSection = () => {
	return (
		<div className='relative flex flex-col justify-center items-center text-center'>
			<h1 className='section-title max-w-screen-lg'>HOW IT WORKS</h1>
			{/* <h1 className='bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500 section-title'>HOW IT WORKS</h1> */}

			{/* Background div that takes up 90vh, leaving space at the bottom for the next section */}
			<div className='relative flex flex-col md:mb-16 max-w-screen-lg doodle'>
				<div className='hidden md:flex flex-col md:mx-4'>
					<img src={howDesktop} alt='How to get your own caricature, visual explanation.' />
					<div className='grid grid-cols-3 grid-rows-2 gap-4 mt-8'>
						<div>{texts.text1}</div>
						<div>{texts.text2}</div>
						<div>{texts.text3}</div>
						<div className='col-start-3 row-start-2'>{texts.note}</div>
					</div>
				</div>
				<div className='relative md:hidden grid grid-cols-1 auto-cols-fr select-none '>
					<div>
						<IndividualStep image={howStep1} text={texts.text1} />
						<div className='grid justify-end mr-[5vw]'>
							<GreenArrowDown className='w-12 h-12 rotate-45' />
						</div>
					</div>
					<div>
						<IndividualStep image={howStep2} text={texts.text2} />
						<div className='grid justify-end mr-[5vw]'>
							<GreenArrowDown className='w-12 h-12 rotate-45' />
						</div>
					</div>
					<div className='relative flex flex-col justify-center text-left h-auto md:h-full'>
						<IndividualStep image={howStep3} text={texts.text3} />
						<Paragraph text={texts.note} />
					</div>
				</div>
			</div>
			<div className='mt-8'>
				<Border />
			</div>
		</div>
	)
}

export default HowItWorksSection
