import React from 'react'
// import {PriceBubble} from '../../img'
// import {ReactFitty} from 'react-fitty'
import {useNavigate} from 'react-router-dom'
import {basicGalleryImages, premiumGalleryImages, standardGalleryImages} from '../../img'
// import {useFormikContext} from 'formik'
import './tiers.css'
import {scrollIntoView} from '../../utils/scroll_into_view'
const simplePic = basicGalleryImages[0]
const standardPic = standardGalleryImages[3] //getRandomCaricature()
const premiumPic = premiumGalleryImages[0]

const TierRow = ({plan}) => {
	const Image = ({className}) => (
		<div className={`${className} items-center max-w-xs`}>
			<img
				src={plan.image}
				alt={plan.name}
				className='rounded-md h-full w-full object-cover object-center aspect-square max-h-60 max-w-60 overflow-hidden '
			/>
		</div>
	)
	return (
		<div className='tier-row my-8 flex flex-row h-full relative max-h-[60vh]'>
			{plan.isPopular && <h5 className='inline absolute -top-5 mt-auto py-1 px-2 rounded text-xs bg-doodle text-white font-lucky'>Most Popular</h5>}
			<Image className='flex md:hidden w-[80%]' />
			<div className='flex flex-col w-full py-2'>
				<div className=''>
					<div className='col-start-2 md:col-start-1 row-start-1 md flex text-center items-center justify-center'>
						{/* <ReactFitty className='tier-name'>{tier}</ReactFitty> */}
						<div className='font-lucky text-[7vi]'>{plan.name}</div>
					</div>
					<Image className='hidden md:flex ' />
				</div>
				<div className='flex flex-col h-full'>
					{/* <div className='description mb-auto py-4'>{plan.description}</div> */}
					<div className='order-2 h-full'>
						<ul className='list-none text-left p-2 text-[3vi] sm:text-[4vi]'>
							{plan.features.map((feature, i) => (
								<li key={i}>
									<span className='text-e_green mr-2'>&#10003;</span>
									{feature}
								</li>
							))}
						</ul>
					</div>
					<p className='order-1 text-[5vi] text-center content-center font-lucky pt-2 '>{plan.price}</p>
					<div className='order-last flex justify-center button-container'>
						{/* <button
					className={`py-2 px-4 rounded font-bold ${
						plan.isPopular ? 'bg-blue-500 hover:bg-blue-700 text-white' : 'bg-gray-300 hover:bg-gray-400 text-gray-800'
						}`}>
						Get now
						</button> */}
						<TierButton tier={plan.name} />
					</div>
				</div>
			</div>
		</div>
	)
}
const TierButton = ({tier}) => {
	const navigate = useNavigate()
	const gotoContact = () => {
		scrollIntoView(null, '#contact')
		navigate(`/#contact?get=${tier}&from=table`)
	}
	// scrollIntoView()
	return (
		<button className='font-lucky text-[4vi]' onClick={gotoContact}>
			Get now!
		</button>
	)
}
const tableData = {
	simple: {
		name: 'Simple',
		image: simplePic,
		// features: ['Line Art', 'Black & White', 'One character only', 'Head and shoulders only', 'No background'],
		// description: '',
		features: ['B&W style art work', 'One caricature', 'High resolution digital file'],
		description: 'This basic tier includes options such as line art and black and white coloring.',
		price: <span>$49</span>,
	},
	standard: {
		name: 'Standard',
		image: standardPic,
		// features: ['All options of Simple tier, plus:', 'Colored artwork', 'One character only', 'Simple background', 'Additional details'],
		features: ['Color rendering', 'Advanced (customized) details', 'One caricature', 'Full figure', 'High resolution digital file'],
		// description: '',
		description:
			'This mid-range tier includes all the options of the Simple tier, as well as additional options such as colored artwork. This is the most popular tier among our customers.',
		price: (
			<span className=''>
				$99<span className='text-2xl'>.99</span>
			</span>
		),
		isPopular: true,
	},
	premium: {
		name: 'Premium',
		image: premiumPic,
		features: ['All in Standard +', 'Complex coloring', 'Full body option', 'Detailed background', 'Video of drawing process'],
		// features: [],
		// description: 'This top tier includes all the options of the Standard tier, as well as advanced options such as a video of the drawing process.',
		description: 'Family caricature portrait, a fine art piece on Perspex Print.',
		price: (
			<span className=''>
				$500<span className='text-xl'> plus</span>
			</span>
		),
	},
}
const TierComparisonTable = () => {
	return (
		<div className='my-4 p-6 h-fit md:max-w-[100vw] grid gap-4 grid-flow-row md:grid-flow-col md:grid-cols-3 justify-center items-center content-center'>
			{Object.values(tableData).map((data) => (
				<TierRow plan={data} key={data.name} />
			))}
		</div>
	)
}

export default TierComparisonTable
