// import './App.css'
// eslint-disable-next-line
// import {Hero, Pros, About, Gallery, Contact, Testimonials, Specials, Tiers, FAQ, Footer} from './containers'

// import {Navbar} from './components'
// import {isIE, isiOS} from './utils/browserCheck'
import {isIE} from './utils/browserCheck'
import {removeNoScript} from './utils/pageCleanup'
// eslint-disable-next-line
import React, {createContext, useCallback, useEffect, useState} from 'react'
import {useEventListener} from './utils/useEventListener'
// import {useWhyDidYouUpdate} from './utils/useWhyDidYouUpdate'
// import {ProgressBar} from './components/progress_bar/progressBar'

// eslint-disable-next-line
import {BrowserRouter, useLocation} from 'react-router-dom'

// import {RouteResolver} from './RouteResolver'
// import {NotFound} from './containers/Common/NotFound'
// import {OrderPage} from './containers/OrderPage/OrderPage'
// import {TermsAndPrivacy} from './containers/Common/TermsAndPrivacy'
import {scrollIntoView} from './utils/scroll_into_view'
// eslint-disable-next-line
import {useFormik} from 'formik'
// import ImageUploader from './containers/Admin/uploadImage'
// import MessageSender from './containers/Admin/messageSender'
// import MyOrdersPage from './containers/MyOrder/MyOrdersPage'
import {UserProvider} from './context/UserContext'
// import {UserProfile} from './containers/Common/UserProfile'
import UserService from './services/UserService'
// import {AdminDataProvider} from './context/AdminDataContext'
// eslint-disable-next-line
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
// eslint-disable-next-line no-unused-vars
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
// import AdminPage from './containers/Admin/AdminPage'
// import CaricaturePage from './containers/User/CaricaturePage'
// import {FaToolbox} from 'react-icons/fa'
// import Login from './containers/Auth/Login'
// import ReactGA from 'react-ga'
import {NotificationProvider} from './context/NotificationContext'
import Contact from './sections/Contact'
// eslint-disable-next-line
import EnoughTalk from './sections/EnoughTalk'
import Gallery from './sections/Gallery'
// eslint-disable-next-line no-unused-vars
import HeroSection from './sections/Hero'
import HowItWorksSection from './sections/HowItWorks'
import ProsSection from './sections/Pros'
import useIsMobile from './utils/cssHelpers'
// eslint-disable-next-line
import TestExample from './sections/Test'
import FAQSection from './sections/FAQ'
import Footer from './sections/Footer'

// eslint-disable-next-line
import ResponsiveWebpage from './sections/hero/index.js'
import LoadingPage from './sections/LoadingPage.js'
// import ReactGA from 'react-ga4'

// eslint-disable-next-line
const queryClient = new QueryClient()
// const Hero = React.lazy(() => import('./containers/hero/hero.js'))
// const About = React.lazy(() => import('./containers/about/about.js'))
// const Gallery = React.lazy(() => import('./containers/gallery/gallery.js'))
// const Testimonials = React.lazy(() => import('./containers/testimonies/testimonials.js'))
// const Specials = React.lazy(() => import('./containers/specials/specials.js'))
// const Tiers = React.lazy(() => import('./containers/tiers/tiers.js'))
// const FAQ = React.lazy(() => import('./containers/faq/faq.js'))
// const Contact = React.lazy(() => import('./containers/contact/contact.js'))
// const Footer = React.lazy(() => import('./containers/footer/footer.js'))

// ReactGA.initialize([
// 	{
// 		trackingId: 'GTM-MFJ7R45',
// 		testMode: true,
// 		//   gaOptions: {...}, // optional
// 		//   gtagOptions: {...}, // optional
// 	},
// 	{
// 		trackingId: 'G-NNH33134QS',
// 		testMode: true,
// 	},
// 	{
// 		trackingId: 'G-DPW6DFQN7D',
// 		testMode: true,
// 	},
// ])

// const sections = {
// 	// need href if inNav=true
// 	hero: {name: 'Hero'},
// 	// home: {name: 'Home', href: '/'},
// 	about: {name: 'About', href: '/about'},
// 	gallery: {name: 'Gallery', href: '/#gallery'},
// 	testimonies: {name: 'Testimonies'},
// 	order: {name: 'Orders (WIP)', href: '/order'},
// 	tiers: {name: 'Tiers'},
// 	faq: {name: 'FAQ'},
// 	contact: {name: 'Contact', href: '/contact'},
// }
const FormContext = createContext()
const MainPage = () => {
	const location = useLocation()
	useEffect(() => {
		if (location.hash) scrollIntoView(null, location.hash)
	}, [location])

	// Send pageview with a custom path
	// ReactGA.send({hitType: 'pageview', page: '/home', title: 'Main page from react-ga4'})

	return (
		<>
			{/* <Hero id={sections.hero.name.toLowerCase()} />
			<Pros id='pros' />
			<Gallery id={sections.gallery.name.toLowerCase()} />
			<Testimonials id={sections.testimonies.name.toLowerCase()} />
			<Tiers id={sections.tiers.name.toLowerCase()} />
			<FAQ id={sections.faq.name.toLowerCase()} />
			<DividerWave direction='up' className='fill-offwhite' />
			<Contact id={sections.contact.name.toLowerCase()} /> */}
		</>
	)
}
const AboutPage = () => {
	// Send pageview with a custom path
	// ReactGA.send({hitType: 'pageview', page: '/about', title: 'About Page'})

	return <>{/* <About id={sections.about.name.toLowerCase()} /> */}</>
}
// const AdminView = () => {
// 	// Send pageview with a custom path
// 	// ReactGA.send({hitType: 'pageview', page: '/admin', title: 'Custom Title'})

// 	return (
// 		<AdminDataProvider>
// 			<AdminPage />
// 		</AdminDataProvider>
// 	)
// }
// const PersonalPage = () => {
// 	return <MyOrdersPage />
// }

const UserRoles = UserService.roles
// eslint-disable-next-line
const routes = [
	// {
	// 	path: '/upload',
	// 	component: ImageUploader,
	// 	accessList: [UserRoles.admin],
	// 	title: 'Upload Page',
	// 	navText: 'upload test page',
	// 	isNavigable: false,
	// 	showNavbar: true,
	// 	showFooter: false,
	// },
	// {
	// 	path: '/admin',
	// 	component: AdminView,
	// 	accessList: [UserRoles.admin],
	// 	navText: (
	// 		<>
	// 			{'Admin'}
	// 			<FaToolbox className='mx-2' />
	// 		</>
	// 	),
	// 	title: 'Admin Page',
	// 	isNavigable: true,
	// 	showNavbar: true,
	// 	showFooter: false,
	// },
	// {
	// 	path: '/myorders',
	// 	component: PersonalPage,
	// 	accessList: [UserRoles.admin],
	// 	title: 'Upload Page',
	// 	navText: 'myorders',
	// 	isNavigable: false,
	// 	showNavbar: true,
	// 	showFooter: true,
	// },
	// {
	// 	path: '/contact',
	// 	navText: 'test contact',
	// 	component: MessageSender,
	// 	accessList: [UserRoles.admin],
	// 	title: 'send message',
	// 	isNavigable: false,
	// 	showNavbar: true,
	// 	showFooter: false,
	// },
	{
		path: '/',
		navText: 'Home',
		component: MainPage,
		accessList: [UserRoles.guest, UserRoles.user, UserRoles.vip, UserRoles.admin],
		title: 'Adelez Studio - Home page',
		isNavigable: true,
		showNavbar: true,
		showFooter: true,
	},
	{
		path: '/#gallery',
		navText: 'Gallery',
		component: MainPage,
		accessList: [UserRoles.guest, UserRoles.user, UserRoles.vip, UserRoles.admin],
		title: 'Adelez Studio - Home page',
		isNavigable: true,
		showNavbar: true,
		showFooter: true,
	},
	// {
	// 	path: '/profile',
	// 	component: UserProfile,
	// 	navText: 'Profile',
	// 	accessList: [UserRoles.user, UserRoles.vip, UserRoles.admin],
	// 	title: 'Profile',
	// 	isNavigable: true,
	// 	showNavbar: true,
	// 	showFooter: true,
	// },
	// {
	// 	path: '/profile',
	// 	component: Login,
	// 	navText: 'Sign in',
	// 	accessList: [UserRoles.guest],
	// 	title: 'Profile',
	// 	isNavigable: false,
	// 	showNavbar: true,
	// 	showFooter: false,
	// },
	{
		path: '/about',
		component: AboutPage,
		navText: 'About',
		accessList: [UserRoles.guest, UserRoles.user, UserRoles.vip, UserRoles.admin],
		title: 'About - Knapp Cartoons',
		isNavigable: true,
		showNavbar: true,
		showFooter: true,
	},
	// {
	// 	path: '/#contact',
	// 	navText: 'Contact',
	// 	component: MainPage,
	// 	accessList: [UserRoles.guest, UserRoles.user, UserRoles.vip, UserRoles.admin],
	// 	title: 'Contact - Knapp Cartoons',
	// 	isNavigable: true,
	// 	showNavbar: true,
	// 	showFooter: true,
	// },
	// {
	// 	path: '/order',
	// 	navText: 'Order Now',
	// 	component: OrderPage,
	// 	isNavigable: true,
	// 	showNavbar: true,
	// 	showFooter: true,
	// 	accessList: [UserRoles.guest, UserRoles.user, UserRoles.vip, UserRoles.admin],
	// 	title: 'Get your own Caricature - Adelez Studio',
	// },
	// {
	// 	path: '/terms', //cannot be an array anymore after upgrading react router
	// 	component: TermsAndPrivacy,
	// 	accessList: [UserRoles.guest, UserRoles.user, UserRoles.vip, UserRoles.admin],
	// 	showNavbar: true,
	// 	title: 'Terms of Use',
	// },
	// {
	// 	path: '/privacy',
	// 	showNavbar: true,
	// 	component: TermsAndPrivacy,
	// 	accessList: [UserRoles.guest, UserRoles.user, UserRoles.vip, UserRoles.admin],
	// 	title: 'Privacy Policy',
	// },
	// {
	// 	path: '/images/:id',
	// 	showNavbar: true,
	// 	component: CaricaturePage,
	// 	accessList: [UserRoles.guest, UserRoles.user, UserRoles.vip, UserRoles.admin],
	// 	title: 'A Caricature',
	// },
	{
		path: '/*',
		component: () => <div>Not Found</div>,
		// component: NotFound,
		showNavbar: true,
		accessList: [UserRoles.guest, UserRoles.user, UserRoles.vip, UserRoles.admin],
		title: 'Adelez Studio - Caricature 404',
	},
]
// eslint-disable-next-line
const defaultRoutes = {
	home: '/',
	order: '/order',
	admin: '/admin',
}

function App() {
	const historyController = (e) => {
		console.log('app historyController', e)
	}
	window.onpopstate = (e) =>
		setTimeout(() => {
			historyController(e)
		}, 0)

	removeNoScript()
	const [navOpen, setNavOpen] = useState(false)

	const isMobile = useIsMobile()
	const scrollChangeHandler = useCallback(() => {
		if (!isMobile)
			if (!navOpen && window.pageYOffset === 0) {
				setNavOpen(true)
			} else setNavOpen(false)
	}, [navOpen, setNavOpen, isMobile])

	useEventListener('scroll', scrollChangeHandler)
	// eslint-disable-next-line
	const toggleNav = (to = null) => {
		setNavOpen((navOpen) => !navOpen)
		// TODO: check if we need to block scroll on mobile and how to implement
		// if (isMobile() && !navOpen) document.body.classList.add('noscroll')
		// else document.body.classList.remove('noscroll')
	}
	// eslint-disable-next-line
	const Nav = () => {
		return (
			<>
				{/* {isiOS ? null : <ProgressBar />} */}
				{/* <Navbar navOpen={navOpen} toggleNav={toggleNav} routes={routes} /> */}
			</>
		)
	}
	const formik = useFormik({
		initialValues: {
			name: '',
			email: '',
			message: '',
		},
		// onSubmit: (values) => {
		// 	// Make API call here with values
		// },
	})
	const [isImageLoaded, setIsImageLoaded] = useState(false)

	// Load the image
	useEffect(() => {
		const img = new Image()
		img.src = '/assets/img/HeroBG_1_D.png'
		img.onload = () => setIsImageLoaded(true)
	}, [])

	const isLoaded = isImageLoaded //isFontLoaded &&

	useEffect(() => {
		if (isLoaded) {
			document.documentElement.style.overflowY = 'hidden'
			document.documentElement.style.removeProperty('overflow-y')
			document.documentElement.style.removeProperty('width')
		}
	}, [isLoaded])

	if (isIE) return <div>IE is not supported. Download Chrome/Firefox/Opera.</div>

	return (
		<NotificationProvider>
			<UserProvider>
				<BrowserRouter>
					{/* <QueryClientProvider client={queryClient}> */}
					<FormContext.Provider value={formik}>
						{/* <RouteResolver routes={routes} defaults={defaultRoutes} /> */}
						<>
							<LoadingPage isLoading={isLoaded} />
							{/* <ResponsiveWebpage /> */}
							<HeroSection />
							<ProsSection />
							<HowItWorksSection />
							<Gallery />

							{/* <TestExample /> */}

							{/* <EnoughTalk /> */}
							<FAQSection />
							<Contact />
							<Footer />
						</>
						{/* Nav={Nav} /> */}
					</FormContext.Provider>
					{/* <ReactQueryDevtools /> */}
					{/* </QueryClientProvider> */}
				</BrowserRouter>
			</UserProvider>
		</NotificationProvider>
	)
}

export default App
