import React, {useRef, useState} from 'react'
// import {useNotification} from '../../context/NotificationContext'
import {Image} from 'antd'
// eslint-disable-next-line
import {UndoOutlined, ZoomInOutlined, ZoomOutOutlined} from '@ant-design/icons'
// const simpleGalleryShuffeld = shuffleArray(basicGalleryImages)
import './gallery_override.css'
// import {FaGift} from 'react-icons/fa'
// eslint-disable-next-line no-unused-vars
// import {BiCart, BiShoppingBag} from 'react-icons/bi'
// import {useNotification} from '../../context/NotificationContext'
import {useLockBodyScroll} from '../../utils/useLockBodyScroll'
// import { scrollIntoView } from '../../utils/scroll_into_view'
// import { useNavigate } from 'react-router-dom'
// import ImagePreviewGroup from './ImagePreviewGroup'
const PreviewToolbarButton = ({onClick, disabled, Icon, text}) => {
	return (
		<button
			onClick={onClick}
			className={`flex flex-col mt-4 items-center w-16 h-16 rounded-full aspect-square justify-center select-none outline-white  ${
				disabled ? 'touch-none cursor-not-allowed text-gray-400 bg-black/10' : 'bg-black/30 backdrop-blur-md hover:outline'
			}`}
			disabled={disabled}>
			<Icon className={`text-lg select-none touch-none pointer-events-none `} />
			{/* <span className={`text-xs mt-1 whitespace-nowrap select-none`}>{text}</span> */}
		</button>
	)
}
const CustomToolbar = (_, {transform: {scale}, actions: {onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn, onReset}}) => {
	// const {setToast} = useNotification()

	// eslint-disable-next-line no-unused-vars
	// const showNotification = () => {
	// 	setToast(`this will bring you to the contact section`)
	// }
	// const navigate = useNavigate()
	// const gotoContact = () => {
	// 	scrollIntoView(null, '#contact')
	// 	navigate(`/#contact?get=${'a'}`)
	// }
	return (
		<div className='absolute bottom-0 right-4'>
			<div className=' flex flex-col rounded-full px-1'>
				<PreviewToolbarButton onClick={onZoomIn} disabled={scale >= 3} Icon={ZoomInOutlined} text={'Zoom In'} />
				<PreviewToolbarButton onClick={onZoomOut} disabled={scale <= 1} Icon={ZoomOutOutlined} text={'Zoom Out'} />
				{/* <PreviewToolbarButton onClick={onReset} disabled={false} Icon={UndoOutlined} text={'Reset'} /> */}
			</div>
		</div>
	)
}
const BlockScroll = () => {
	useLockBodyScroll()
	return <></>
}
const GalleryGrid = ({images, aspectSquare = false}) => {
	const [isPreviewOpen, setIsPreviewOpen] = useState(false)
	const imageRefs = useRef([])
	// const [currentIndex, setCurrentIndex] = useState(0)

	const handleImageChange = (current, prev, ...props) => {
		// console.log(current, prev, ...props)
		// const nextIndex = (current + 1) % images.length
		// setCurrentIndex(nextIndex)

		// Scroll to the current image
		if (imageRefs.current[current]) {
			imageRefs.current[current].scrollIntoView({
				behavior: 'smooth',
				block: 'center',
				inline: 'center',
			})
		}
	}

	// const [transform, setTransform] = useState({scale: 1, rotate: 0, flipX: false, flipY: false})

	// const handleTransform = (newTransform) => {
	// onTransform: {transform: '', action: 'dragRebound'}?
	// 	setTransform(newTransform)
	// }

	const customImageRender = (originalNode, {transform}) => {
		// console.log('originalNode', originalNode)
		return (
			<div
				onContextMenu={(e) => e.preventDefault()}
				className='h-auto max-w-[100vw] align-middle cursor-grab select-none'
				// max-h-[70vh]
				style={{
					// transform: `scale(${transform.scale})`,
					// transformOrigin: '0% 0%',
					transition: 'transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s',
				}}>
				{originalNode}
			</div>
		)
	}
	return (
		<>
			{isPreviewOpen ? <BlockScroll /> : <></>}
			<div className='image-grid'>
				<Image.PreviewGroup
					preview={{
						onVisibleChange: (visible) => setIsPreviewOpen(visible),
						onChange: handleImageChange,
						destroyOnClose: true,
						toolbarRender: CustomToolbar,
						// toolbarRender: Math.random() < 0.5 ? CustomToolbar : () => <></>,

						// onTransform: handleTransform,
						imageRender: customImageRender,
					}}>
					{images.slice(0, 9).map((img, index) => (
						<div
							key={index}
							ref={(el) => (imageRefs.current[index] = el)}
							//
							className={`image-grid-item group select-none `}>
							<Image
								onContextMenu={(e) => e.preventDefault()}
								src={img}
								alt=''
								className={`h-full select-none max-w-xs ${aspectSquare ? 'aspect-square' : ''}`}
								style={{
									height: '100%',
									objectFit: 'cover',
									transition: 'transform 0.3s ease-in-out',
								}}
							/>
						</div>
					))}
				</Image.PreviewGroup>
			</div>
		</>
	)
}

export default GalleryGrid
