import React from 'react'

import {isProduction} from '../utils/config'

import {ReactComponent as SecretaryStandbyDarkDress} from './contact/SecretaryStandby_DarkDress.svg'
import {ReactComponent as SecretaryStandbyLineDress} from './contact/SecretaryStandby_LineDress.svg'
import {ReactComponent as SendNoteDarkDress} from './contact/SendNote_DarkDress.svg'
import {ReactComponent as SendNoteLineDress} from './contact/SendNote_LineDress.svg'
import {ReactComponent as TakingNotesDarkDress} from './contact/TakingNotes_DarkDress.svg'
import {ReactComponent as TakingNotesLineDress} from './contact/TakingNotes_LineDress.svg'

// eslint-disable-next-line no-unused-vars
const baseUrl = process.env.ASSETS_BASE_URL | 'https://assets.adelezstudio.com'
// eslint-disable-next-line no-unused-vars
const serveFromLocal = isProduction ? false : true
// for (let x in Caricatures) {
// 	console.log(x)
// }
// eslint-disable-next-line
function importAll(r, asArray = true) {
	// let images = {}
	let imagesArray = []

	r.keys().forEach((item) => {
		// images[item.replace('./', '')] = r(item)
		imagesArray.push(r(item))
	})
	// console.log('importAll', images, imagesArray)
	// if (asArray) return imagesArray
	// else return images
	return imagesArray
}

const basicGalleryImages = importAll(require.context('./gallery/basic', false, /\.(png|jpe?g|svg)$/))
const standardGalleryImages = importAll(require.context('./gallery/standard', false, /\.(png|jpe?g|svg)$/))
const premiumGalleryImages = importAll(require.context('./gallery/premium', false, /\.(png|jpe?g|svg)$/))
const customGalleryImages = importAll(require.context('./caricatures/custom', false, /\.(png|jpe?g|svg)$/))

const caricatures = [...basicGalleryImages, ...standardGalleryImages, ...premiumGalleryImages, ...customGalleryImages]
export {basicGalleryImages, caricatures, customGalleryImages, premiumGalleryImages, standardGalleryImages}

export const getRandomCaricature = (amount = 1) => {
	const savedCaris = []
	const data = []
	const maxTry = caricatures.length
	for (let i = 1, m = 1; i <= amount && m <= maxTry; i++, m++) {
		const randomNumber = Math.floor(Math.random() * (maxTry - 1) + 1)

		if (m === maxTry) console.log('m = maxTry reached in getRandomCaricature')
		if (savedCaris.includes(randomNumber)) i--
		else {
			data.push(caricatures[randomNumber])
			savedCaris.push(randomNumber)
		}
	}

	return data
}

//hero
export {default as HeroBG_HalfTone_D_1440_900} from './hero/BG_HalfTone_D_1440_900.jpg'
export {default as HeroBG_Solid_D_1440_900} from './hero/BG_Solid_D_1440_900.jpg'
export {default as HeroBG_Doodles_D_1440_900} from './hero/BG_Doodles_D_1440_900.png'

export {default as HeroBG_Doodles_M} from './hero/BG_Doodles_M.png'

export {default as HeroBG_All_D} from './hero/BG_All_D.jpg'
export {default as HeroBG_All_M} from './hero/BG_All_M.jpg'

export {default as HeroBG_D} from './hero/BG_Plain_D.jpg'
export {default as HeroBG_M} from './hero/BG_Plain_M.jpg'

export {default as HeroBG_Chars_D} from './hero/BG_Chars_D.jpg'
export {default as HeroBG_Chars_M} from './hero/BG_Chars_M.jpg'
export {default as Char_AllSmiles} from './hero/Char_AllSmiles.png'
export {default as Char_AxlRose} from './hero/Char_AxlRose.png'
export {default as Char_BradPitt} from './hero/Char_BradPitt.png'
export {default as Char_CoolBlackLady} from './hero/Char_CoolBlackLady.png'
export {default as Char_JimCarry} from './hero/Char_JimCarry.png'
export {default as Char_TimCha} from './hero/Char_TimCha.png'
export {default as Hero_ShowGirl} from './hero/Hero_ShowGirl.png'

export {ReactComponent as ForGallery} from './svg/ForGallery.svg'
export {ReactComponent as GreenArrowDown} from './svg/green_arrow_down.svg'
export {ReactComponent as Logo} from './svg/logo.svg'
export {ReactComponent as LogoTxt} from './svg/logo_txt.svg'

//pros section
export {default as marketingArt} from './pros/Pros_Marketing.png'
export {default as meaningfulTouchArt} from './pros/Pros_PersonalTouch.png'
export {default as updateSocialArt} from './pros/Pros_SocialMedia.png'
export {default as unforgettableGiftArt} from './pros/Pros_UnforgettableGift.png'

export {ReactComponent as marketingDoodle} from './svg/pros_icon-cash.svg'
export {ReactComponent as meaningfulTouchDoodle} from './svg/pros_icon-pizza.svg'
export {ReactComponent as unforgettableGiftDoodle} from './svg/pros_icon-present.svg'
export {ReactComponent as updateSocialDoodle} from './svg/pros_icon-profile.svg'

//gallery
export {default as ContactBG} from './doodles/ContactBG.png'
export {default as Gallery_ShowBoy} from './gallery/Gallery_ShowBoy.png'

export {default as GalleryBlackWhiteLeft} from './gallery/GalleryBlackWhiteLeft.png'
export {default as GalleryBlackWhiteRight} from './gallery/GalleryBlackWhiteRight.png'

export {default as GalleryColorLeft} from './gallery/GalleryColorLeft.png'
export {default as GalleryColorRight} from './gallery/GalleryColorRight.png'

export {default as GalleryPainterlyLeft} from './gallery/GalleryPainterlyLeft.png'
export {default as GalleryPainterlyRight} from './gallery/GalleryPainterlyRight.png'

//enough talk
export {default as EnoughTalk_BottomDoodle_D} from './doodles/EnoughTalk_BottomDoodle_D.png'
export {default as EnoughTalk_BottomDoodle_M} from './doodles/EnoughTalk_BottomDoodle_M.png'
export {default as EnoughTalk_ExcitedWomen_D} from './doodles/EnoughTalk_ExcitedWomen_D.png'
export {default as EnoughTalk_ExcitedWomen_M} from './doodles/EnoughTalk_ExcitedWomen_M.png'

export {ReactComponent as AKCariSitting} from './about/AKCariSitting.svg'
export {ReactComponent as DoodleOnText} from './about/DoodleOnText.svg'

export {ReactComponent as CopyCop} from './doodles/CopyCop.svg'
const lineOrDark = Math.random() * 2 > 1
console.log('using random secretary dress style true=lines, false=dark : ', lineOrDark)

export const SendNote = () => (lineOrDark ? <SendNoteLineDress /> : <SendNoteDarkDress />)
export const SecretaryStandby = () => (lineOrDark ? <SecretaryStandbyLineDress /> : <SecretaryStandbyDarkDress />)
export const TakingNotes = () => (lineOrDark ? <TakingNotesLineDress /> : <TakingNotesDarkDress />)
// eslint-disable-next-line
export default {SendNote, SecretaryStandby, TakingNotes}
