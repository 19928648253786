import React, {useEffect, useRef, useState} from 'react'
// import { useEffect, useState } from 'react';
// eslint-disable-next-line
import Border from '../components/Border'
import {
	Char_AllSmiles,
	Char_AxlRose,
	Char_BradPitt,
	Char_CoolBlackLady,
	Char_JimCarry,
	Char_TimCha,
	Hero_ShowGirl,
	HeroBG_Chars_D,
	HeroBG_Chars_M,
	HeroBG_D,
	HeroBG_M,
	LogoTxt,
} from '../img'
import useIsMobile from '../utils/cssHelpers'
import CustomGlobalButton from '../components/CustomGlobalButton'
import {useNavigate} from 'react-router-dom'
import {scrollIntoView} from '../utils/scroll_into_view'
// import {useNotification} from '../context/NotificationContext'
// eslint-disable-next-line
const BG_With_Dynamic_Chars = () => {
	const isMobile = useIsMobile()
	const navigate = useNavigate()
	const gotoContact = () => {
		scrollIntoView(null, '#contact')
		navigate(`/#contact?from=hero`)
	}
	return (
		<>
			<div className='relative w-full bg-cover bg-center z-10 h-[90vh]' style={{backgroundImage: `url(${isMobile ? HeroBG_M : HeroBG_D})`}}>
				{/* right side */}
				<div className='absolute top-[25%] md:top-0 left-0 h-[30%] md:h-[55%]'>
					{/* top right */}
					<img src={Char_JimCarry} alt='Jim Carry' className='w-full h-full object-contain' />
				</div>
				<div className='absolute bottom-0 left-0 h-[40%] md:h-[60%] '>
					{/* bottom right */}
					<img src={Char_AxlRose} alt='Ask Rose' className='w-full h-full object-contain' />
				</div>
				{/* left side */}
				<div className='absolute top-16 md:bottom-0 md:mt-0 right-0 md:right-[35vw] z-10 md:z-30 h-[50%] md:h-[75%] '>
					{/* mid right mid center */}
					<img src={Char_CoolBlackLady} alt='Cool Black Lady' className='w-full h-full object-contain' />
				</div>
				<div className='absolute bottom-[20%] md:bottom-0 right-10 md:right-[15vw] z-40 h-[50%] md:h-[90%] '>
					{/* mid right center */}
					<img src={Char_TimCha} alt='Cool Black Lady' className='w-full h-full object-contain' />
				</div>
				<div className='absolute top-[20%] md:top-0 right-[-10%] md:right-0 z-20 md:z-10 h-[50%] md:h-[90%] '>
					{/* top right background */}
					<img src={Char_BradPitt} alt='Brad Pitt' className='w-full h-full object-contain' />
				</div>
				<div className='hidden md:block absolute bottom-0 right-0 z-20 h-[70%]'>
					{/* bottom left */}
					<img src={Char_AllSmiles} alt='All Smiles' className='w-full h-full object-contain' />
				</div>
				<div className='relative self-start  grid grid-rows-3 grid-cols-3 gap-4 h-full w-full px- z-50'>
					<div className='row-span-1 col-span-3 flex justify-center items-end text-white'>
						<LogoTxt className='h-24  ' />
					</div>
					<div className='row-span-1 col-span-3 flex justify-center items-center '>
						<h1 className='text-white font-bold text-center font-lucky justify-center text-5xl xs:text-7xl drop-shadow-full tracking-wide xs:tracking-wider z-20'>
							{/* GET YOUR CARICATURE */}
							GET YOUR
							<br />
							CARICATURE
						</h1>
					</div>
					<div className='row-span-1 col-span-3 flex justify-center items-start relative z-40'>
						<CustomGlobalButton onClick={gotoContact} className='self-start' hero={true} />
					</div>
				</div>
			</div>
		</>
	)
}
// eslint-disable-next-line
const HeresWhyText = ({className}) => {
	return (
		<h1 className={className}>
			<span className='text-e_blue pr-4 xs:pr-8'>{"HERE'S"}</span>
			<span className='text-e_red'>{'WHY'}</span>
		</h1>
	)
}
// eslint-disable-next-line
const BGPart = () => {
	const isMobile = useIsMobile()
	// const {setToast} = useNotification()

	// const showNotification = () => {
	// 	setToast(`this will bring you to the contact section`)
	// }
	const navigate = useNavigate()
	const gotoContact = () => {
		scrollIntoView(null, '#contact')
		navigate(`/#contact?from=hero`)
	}
	return (
		<div
			className='z-10 relative bg-black/50 
			grid grid-rows-1 
			grid-cols-1 tablet:grid-cols-3 pc:grid-cols-5 
			h-[85%] 
			bg-cover bg-left lg:bg-center
			justify-items-center'
			// items-center
			// h-auto
			style={{backgroundImage: `url(${isMobile ? HeroBG_Chars_M : HeroBG_Chars_D})`}}>
			{/* <div className='absolute inset-0 bg-black bg-opacity-50'></div> */}
			{/* <img src={isMobile ? HeroBG_Chars_M : HeroBG_Chars_D} alt='' className='z-10 absolute h-full w-full' /> */}
			<div
				className='px-8 tablet:col-start-2 
				flex flex-col justify-around 
			h-full relative'>
				{/* // grid grid-rows-1 gird-rows-[auto_auto_auto]  */}
				<div className='flex justify-center items-end text-white my-['>
					<LogoTxt className='h-24  ' />
				</div>
				<div className='flex justify-center items-center mb-[10%]'>
					<h1 className='text-white font-bold text-center font-lucky justify-center text-5xl tablet:text-7xl pc:text-8xl drop-shadow-full tracking-wide xs:tracking-wider z-20 select-none'>
						{/* GET YOUR CARICATURE */}
						GET YOUR
						<br />
						CARICATURE
					</h1>
				</div>
				<div className=' flex justify-center items-start relative z-40 px-4 self-start max-phone:place-self-start mb-[5%]'>
					<CustomGlobalButton onClick={gotoContact} className='self-start' hero={true} />
				</div>
			</div>
		</div>
	)
}
// eslint-disable-next-line no-unused-vars
const TopPart2 = () => {
	const isMobile = useIsMobile()

	return (
		<div className=''>
			<div className='relative overflow-hidden'>
				{/* className={`object-cover h-[90vh] w-full ${isMobile ? 'origin-center' : 'origin-left'}`}*/}
				<img src={isMobile ? HeroBG_Chars_M : HeroBG_Chars_D} alt='Hero Background' className='-w-full w-full h-[90vh] object-cover' />
			</div>
			{/* Background div that takes up 90vh, leaving space at the bottom for the next section */}
			{/* <div className='absolute top-0 bottom-0 right-32 left-32 z-20'> */}
			<div className='absolute top-0 bottom-0 right-0 left-0 z-20'>
				<div className='relative h-[100vh] grid grid-cols-3 lg:grid-cols-5'>
					<div className='col-start-2 flex flex-col text-white text-center items-center justify-center w-full h-[90vh]'>
						<LogoTxt className='max-h-[15vh] xs:w-full h-full mb-24 fill-white z-20' />
						<h1 className='mt-4 font-lucky justify-center text-5xl xs:text-7xl drop-shadow-full tracking-wide xs:tracking-wider z-20'>
							GET YOUR
							<br />
							CARICATURE
						</h1>
						<button className='mt-16 bg-rose-700 hover:bg-rose-600 text-white font-bold py-2 px-4 rounded-full z-20'>Order now!</button>
						{/* <img
							src={isMobile ? HeroBG_Chars_M : HeroBG_Chars_D}
							alt='Hero Background'
							className='absolute top-0 left-0 max-w-full w-full h-[90vh] object-cover'
						/> */}
					</div>
				</div>
			</div>
		</div>
	)
}
// eslint-disable-next-line no-unused-vars
const TopPart3 = () => {
	const isMobile = useIsMobile()

	return (
		<>
			<div className='relative flex flex-col h-[100vh]'>
				<div className='flex flex-col text-white text-center items-center justify-center xs:w-1/2 max-w-screen-sm xs:mr-auto xs:ml-10 h-[90vh]'>
					<LogoTxt className='max-h-[15vh] max-w-[250px] w-full h-full mb-24 fill-white z-20' />
					<h1 className='mt-4 font-lucky justify-center text-8xl drop-shadow-full tracking-wide xs:tracking-wider z-20'>
						GET YOUR
						<br />
						CARICATURE
					</h1>
					<button className='mt-16 bg-rose-700 hover:bg-rose-600 text-white font-bold py-2 px-4 rounded-3xl z-20'>Call to Action</button>
					<img
						src={isMobile ? HeroBG_Chars_M : HeroBG_Chars_D}
						alt='Hero Background'
						className='absolute top-0 right-0 max-w-full w-full h-[90vh] object-cover'
					/>
				</div>
				<div className='flex justify-start xs:justify-center z-1 text-left xs:text-center pt-4'>
					<div className='max-w-screen-lg flex flex-row w-full xs:w-auto text-end items-end relative'>
						<h1 className='z-30 relative w-full flex justify-center xs:mr-[100px] font-lucky text-5xl xs:text-7xl tracking-wide xs:tracking-wider leading-none'>
							<span className='text-e_blue pr-4 xs:pr-8'>{"HERE'S"}</span>
							<span className='text-e_red'>{'WHY'}</span>
						</h1>
						<img src={Hero_ShowGirl} alt='Overlay' className='absolute bottom-0 right-0 h-auto w-64 max-w-[50vw]' />
					</div>
				</div>
			</div>{' '}
		</>
	)
}

const HeroSection = () => {
	// eslint-disable-next-line no-unused-vars
	const isMobile = useIsMobile()
	const baseUrl = '/'
	const BG_D = `${baseUrl}assets/img/HeroBG_1_D.png`
	const BG_M = `${baseUrl}assets/img/HeroBG_1_M.png`
	// eslint-disable-next-line
	const imgRef = useRef(null)
	// eslint-disable-next-line
	const areaRef1 = useRef(null)
	// eslint-disable-next-line
	const areaRef2 = useRef(null)

	const [imgUrl, setImgUrl] = useState(isMobile ? BG_M : BG_D)
	const [translateX, setTranslateX] = useState(isMobile ? '39.5%' : '35.5%')
	const [translateY, setTranslateY] = useState(isMobile ? '70.7%' : '71.5%')
	const [textSize, setTextSize] = useState(isMobile ? '4vw' : '1.7vw')
	const [textPadding, setTextPadding] = useState(isMobile ? '4vw' : '1.7vw')

	useEffect(() => {
		setImgUrl(isMobile ? BG_M : BG_D)
		setTranslateX(isMobile ? '39.5%' : '37.6%')
		setTranslateY(isMobile ? '70.7%' : '69.9%')
		setTextSize(isMobile ? '4.2vw' : '1.7vw')
		setTextPadding(isMobile ? '4.2vw' : '1.7vw')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMobile])
	const navigate = useNavigate()
	const gotoContact = () => {
		scrollIntoView(null, '#contact')
		navigate(`/#contact?from=hero`)
	}

	return (
		<div className='relative flex justify-center items-center mx-auto w-[100svw] flex-col'>
			{/* 
			div - w-full min-h-[1px] relative
				div
					img
					icon/btn - 
			*/}
			<div className='w-full min-h-[1px] relative flex justify-center items-center'>
				{/* <div className='w-full grid'> */}
				<img
					className='z-10 w-full pointer-events-none'
					alt=''
					loading='lazy'
					// decoding='async'
					// width='2539'
					// height='1318'
					src={imgUrl}
					// src='https://vr360action.com/wp-content/uploads/2022/09/Asset-4@6x.png'
					// srcset_hide='https://vr360action.com/wp-content/uploads/2022/09/Asset-4@6x.png 2539w, https://vr360action.com/wp-content/uploads/2022/09/Asset-4@6x-300x156.png 300w, https://vr360action.com/wp-content/uploads/2022/09/Asset-4@6x-1024x532.png 1024w, https://vr360action.com/wp-content/uploads/2022/09/Asset-4@6x-768x399.png 768w, https://vr360action.com/wp-content/uploads/2022/09/Asset-4@6x-1536x797.png 1536w, https://vr360action.com/wp-content/uploads/2022/09/Asset-4@6x-2048x1063.png 2048w'
					// sizes='(max-width: 2539px) 100vw, 2539px'
				/>
				<div
					className={`absolute z-20 -translate-x-1/2 -translate-y-1/2 opacity-0`}
					// -webkit-transform: translate(calc(-1 * var(--hotspot-translate-x)),calc(-1 * var(--hotspot-translate-y)));\
					// -ms-transform: translate(calc(-1 * var(--hotspot-translate-x)),calc(-1 * var(--hotspot-translate-y)));\
					style={{
						'--hotspot-translate-x': translateX,
						'--hotspot-translate-y': translateY,
						left: translateX,
						top: translateY,
						// transform: 'translate(calc(-1 * var(--hotspot-translate-x)),calc(-1 * var(--hotspot-translate-y)))',
					}}>
					<div
						className={`rounded-full font-lucky text-center tracking-[0.15px] 
							appearance-none select-none cursor-pointer
							 outline-white bg-white bg-opacity-40`}
						style={{
							padding: textPadding,
							fontSize: textSize,
						}}
						onClick={gotoContact}>
						get mine now
					</div>
					<CustomGlobalButton onClick={gotoContact} className='font-lucky' hero={true} />
				</div>
				{/* </div> */}
			</div>
			{/* Background div that takes up 90vh, leaving space at the bottom for the next section */}
			{/* <div className='absolute w-full flex flex-col justify-center items-center'> */}
			{/* <img className='' alt='' src={imgUrl}></img> */}
			{/* <div className=' flex justify-center items-start relative z-40 px-4 self-start max-phone:place-self-start mb-[5%]'> */}

			{/* <HeresWhyText className={'section-title z-30 w-full xs:mr-[100px]'} /> */}
			{/* <div class='elementor-element elementor-element-390d581 de_scroll_animation_no elementor-widget elementor-widget-hotspot'>
					<div class='elementor-widget-container'>
						<link rel='stylesheet' href='https://vr360action.com/wp-content/plugins/elementor-pro/assets/css/widget-hotspot.min.css' />

						<div class='e-hotspot elementor-repeater-item-c610c03  e-hotspot--position-left e-hotspot--position-top'>
							<div class='e-hotspot__button e-hotspot--expand'>
								<div class='e-hotspot__icon'>
									<i class='fas fa-plus-circle'></i>
								</div>
							</div>

							<div class='e-hotspot__direction-mask e-hotspot--tooltip-position'>
								<div class='e-hotspot__tooltip   e-hotspot--fade-direction e-hotspot--tooltip-animation-from-left'>
									<p>USA</p>{' '}
								</div>
							</div>
						</div>

						<div class='e-hotspot elementor-repeater-item-cca0ed8  e-hotspot--position-left e-hotspot--position-top' />
					</div>
				</div> */}
			{/* </div> */}
			<Border />
		</div>
	)
}

export default HeroSection
