import React from 'react'
// eslint-disable-next-line

// import Border from '../../components/Border'
import {
	ForGallery,
	GalleryBlackWhiteLeft,
	GalleryBlackWhiteRight,
	GalleryColorLeft,
	GalleryColorRight,
	GalleryPainterlyLeft,
	GalleryPainterlyRight,
	Gallery_ShowBoy,
	basicGalleryImages,
	premiumGalleryImages,
	standardGalleryImages,
} from '../../img'
import GalleryGrid from './GalleryGrid'
import CustomGlobalButton from '../../components/CustomGlobalButton'
// import {useNotification} from '../../context/NotificationContext'
import {useNavigate} from 'react-router-dom'
import {scrollIntoView} from '../../utils/scroll_into_view'
// import ImagePreviewGroup from './ImagePreviewGroup'
// import ImageCarousel from './Carousel'
// import Slider from 'react-slick'

const GalleryPart = ({
	data: {
		images = [],
		highlights = ['example1', 'example2'],
		useFor = true,
		title = 'example',
		subTitle = 'substitle',
		text = 'more example... long',
		button = null,
		darkTheme = false,
		doodleRight = null,
		doodleLeft = null,
	},
}) => {
	const baseUrl = '/'
	const darkBGImage = `${baseUrl}assets/img/GalleryPremiumBG.jpg`
	// const {setToast} = useNotification()

	// const showNotification = () => {
	// 	setToast(`this will bring you to the contact section`)
	// }
	const navigate = useNavigate()
	const gotoContact = () => {
		scrollIntoView(null, '#contact')
		navigate(`/#contact?get=${title}&from=gallery`)
	}
	// let sliderRef = useRef(null)

	// const settings = {
	// 	dots: true,
	// 	infinite: true,
	// 	slidesToShow: 3,
	// 	slidesToScroll: 1,
	// 	autoplay: false,
	// 	autoplaySpeed: 2000,
	// }
	const SideDoodle = ({src, className}) => {
		return doodleRight ? (
			<div className={`w-full max-w-[90vw] sm:max-w-[500px] flex justify-center ${className}`}>
				<img src={src} alt='' className='doodle max-h-[30vh]' />
			</div>
		) : (
			<></>
		)
	}
	return (
		<div className={`gallery-section ${!darkTheme ? 'bg-white' : `bg-black`}`}>
			{darkTheme ? (
				<div
					className={`z-0 bg-black opacity-50 absolute inset-0 bg-cover bg-center`}
					style={{
						backgroundImage: `url('${darkBGImage}')`,
					}}
				/>
			) : (
				<></>
			)}
			<div className='md:pt-16 z-20'>
				<GalleryGrid images={images} aspectSquare={!darkTheme} />
			</div>
			<div className='info-section'>
				<SideDoodle src={doodleLeft} className='hidden md:block' />
				<div className='flex flex-col text-center justify-center items-center '>
					<h2 className={`${darkTheme ? 'text-white' : 'text-black'} mt-8 text-4xl font-lucky`}>
						{title}
						<span className={`opacity-70 text-2xl text-e_gray`}>/{subTitle}</span>
					</h2>
					<p className={`${darkTheme ? 'text-white' : 'text-black'} w-2/3 md:w-3/5 text-center md:text-start mt-8 flex-grow`}>{text}</p>
					<SideDoodle src={doodleLeft} className={`w-full md:hidden block ${darkTheme ? 'order-last -mt-[40px]' : 'mt-4'}`} />
					{useFor ? <ForGallery className='rotate-[25deg] w-16 h-16 mt-4 md:mt-12' /> : <></>}
					<ul className='font-lucky text-e_red text-2xl mt-2 text-left'>
						{highlights.map((t, key) => {
							return <li key={key}>· {t}</li>
						})}
					</ul>
					{/* TODO add button */}
					{<CustomGlobalButton onClick={gotoContact} gallery={darkTheme ? false : true} galleryPremium={darkTheme ? true : false} className='my-8' />}
				</div>
				<SideDoodle src={doodleRight} className='hidden md:block' />
			</div>
		</div>
	)
}

const gallerySections = [
	{
		images: basicGalleryImages,
		highlights: ['BIRTHDAY CARDS', 'INVITATIONS', 'THANK YOU CARDS'],
		text: 'Get a charming, well-designed caricature, Stylized with that classic "cartoony" feel!',
		useFor: true,
		title: 'BLACK & WHITE',
		subTitle: 'BASIC',
		doodleRight: GalleryBlackWhiteRight,
		doodleLeft: GalleryBlackWhiteLeft,
	},
	{
		images: standardGalleryImages,
		highlights: ['GRADUATIONS', 'BIRTHDAYS', 'WEDDING GIFTS', 'YOUR FAVORITE PET'],
		text: 'Elevate your caricature to the next level with energetic watercolors that instantly brings to life and creates a lasting impression.',
		useFor: true,
		title: 'COLOR',
		subTitle: 'STANDARD',
		doodleRight: GalleryColorRight,
		doodleLeft: GalleryColorLeft,
	},
	{
		images: premiumGalleryImages,
		highlights: [],
		darkTheme: true,
		text: (
			<>
				Make a statement with a dynamic, painterly fine art piece, that captures the heart of your family.
				<br />
				An exceptionally meaningful gift, personalized to bring a smile on your face every time you see it.
			</>
		),
		useFor: false,
		title: 'PAINTERLY',
		subTitle: 'PREMIUM',
		doodleRight: GalleryPainterlyRight,
		doodleLeft: GalleryPainterlyLeft,
	},
]
const Gallery = () => {
	return (
		<div id='gallery' className='relative flex flex-col justify-start text-center items-center min-h-[100vh] w-screen'>
			<div className='flex justify-end w-full h-auto md:absolute right-0 top-0 z-10 md:-translate-y-[40%] pointer-events-none select-none touch-none'>
				<img src={Gallery_ShowBoy} alt='Gallery Show Boy' className='doodle max-w-[40vw] h-full md:max-w-[30vw]' />
			</div>
			<h1 className='section-title'>GALLERY</h1>
			{/* <div className=''> */}
			{gallerySections.map((section, key) => {
				return (
					<div key={key}>
						<GalleryPart data={section} />
					</div>
				)
			})}
			{/* </div> */}
			{/* <div className='mt-8'>
				<Border />
			</div> */}
		</div>
	)
}

export default Gallery
