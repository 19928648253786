import {useEffect, useState} from 'react'

// Helper function to get the computed string value for a CSS variable
const getCSSVariable = (propertyName = '', element = document.body) => getComputedStyle(element).getPropertyValue(propertyName)

// Function to get the mobile breakpoint width
const getMobileBreakpointWidth = () => {
	const mobileBreakpoint = getCSSVariable('--mobile-breakpoint')
	return parseInt(mobileBreakpoint) || 680 // Default to 850 if not set
}

// Custom hook to detect screen size changes
const useIsMobile = (size = 680) => {
	const [isMobile, setIsMobile] = useState(false)
	// const [maxSize, setMaxSize] = useState(size)

	// useEffect(() => {
	// 	if (size) setMobileSize(size)
	// }, [size])

	useEffect(() => {
		const updateIsMobile = () => {
			const mobileBreakpointWidth = size || getMobileBreakpointWidth()
			setIsMobile(window.innerWidth <= mobileBreakpointWidth)
		}

		// Initial check
		updateIsMobile()

		// Add event listener for window resize
		window.addEventListener('resize', updateIsMobile)

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener('resize', updateIsMobile)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return isMobile
}
export default useIsMobile
// // React component
// const ResponsiveComponent = () => {
// 	const isMobile = useIsMobile()

// 	return <div>{isMobile ? <p>You are viewing on a mobile screen</p> : <p>You are viewing on a desktop screen</p>}</div>
// }

// export default ResponsiveComponent
